<template>
  <div class="main">
    <!-- loading -->
    <div
      class="loadings"
      v-if="loading"
      v-loading="{ visible: loading, text: loadingtext }"
    ></div>
    <islogin />
    <nyheads />

    <div class="ny-zhuti">
      <nrnav />
      
      <div class="ny-liucheng">
        <div class="ny-liucheng-title">智言论文</div>
        <div class="ny-liucheng-ul">
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">1</div>
              <div class="ny-liucheng-li-title-wz">选择模型</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">2</div>
              <div class="ny-liucheng-li-title-wz">提交字词</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">3</div>
              <div class="ny-liucheng-li-title-wz">参考文献</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">4</div>
              <div class="ny-liucheng-li-title-wz">聚类分析</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">5</div>
              <div class="ny-liucheng-li-title-wz">文献精选</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">6</div>
              <div class="ny-liucheng-li-title-wz">深度评估</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
          <div class="ny-liucheng-li">
            <div class="ny-liucheng-li-title">
              <div class="ny-liucheng-li-title-icon">7</div>
              <div class="ny-liucheng-li-title-wz">拿到论文</div>
            </div>
            <div class="ny-liucheng-li-bcnr"></div>
          </div>
        </div>
      </div>

      <div class="ny-neirong">
        <div class="ny-nav">
          <router-link to="/">论文助手</router-link
          >&nbsp;&nbsp;>&nbsp;&nbsp;智言论文
        </div>
        <div class="ny-con">
          <div class="tjlw">
            <div class="tjlw-img">
              <div class="tjlw-img-txt">
                <el-image
                  style="width: 48px; height: 40px"
                  src="/img/tijiaobiaoti/icon1.png"
                  fit="fill"
                />
                <span
                  >你只负责输入标题，写论文的这三个月，论文助手来帮你节省</span
                >
              </div>
              <el-image
                style="width: 1000px; height: 119px"
                src="/img/tijiaobiaoti/bj1.png"
                fit="fill"
              />
            </div>
            <div class="tjlw-title">智言论文</div>
            <el-form
              :label-position="'top'"
              label-width="200px"
              :model="form"
              style="max-width: 800px"
            >
              <el-form-item label="请选择论文模型">
                <el-radio-group v-model="moban.moban" @change="changemoban">
                  <el-radio
                    v-for="(item, key) in moban.options"
                    :key="key"
                    :value="item.mbid"
                    :label="item.mbname"
                  ></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="提交论文标题/关键词"
                class="biaotiyouhua-item"
              >
                <el-input
                  rows="1"
                  type="textarea"
                  v-model="title.bt"
                  placeholder="请输入论文标题/关键词"
                />
                <div class="ckwx-btn">
                  <el-button type="primary" @click="shengchengcankaowenxian"
                    >获取参考文献</el-button
                  >
                </div>

                <!-- <el-input
              v-model="title.bt"
              style="max-width: 800px"
              placeholder="请输入论文标题/关键词"
            >
              <template #append>
                <el-button type="primary" @click="shengchengcankaowenxian"
                  >获取参考文献</el-button
                >
              </template>
            </el-input> -->
                <div class="biaotiyouhua" v-if="title.istitle">
                  <div class="biaotiyouhua-title">
                    <el-icon :size="16">
                      <TrophyBase />
                    </el-icon>
                    &nbsp;论文助手智能推荐标题（原创）
                  </div>
                  <div class="biaotiyouhua-bt">
                    <div
                      class="biaotiyouhua-bts"
                      v-for="item in title.titleoptions"
                      :key="item"
                      @click="selecttitle(item)"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div class="biaotiyouhua-hyp" @click="gettitle">
                    <el-icon :size="16" color="#2489f2">
                      <Refresh />
                    </el-icon>
                    &nbsp;换一批
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                v-if="cankaowenxian.list.length > 0"
                label="请选择参考文献"
              >
                <div :class="isshowmore == true ? 'cankaowx' : 'cankaowx2'">
                  <el-checkbox-group
                    v-model="cankaowenxian.checkeds"
                    @change="changecankaowenxian"
                  >
                    <el-checkbox
                      v-for="(item, key) in cankaowenxian.list"
                      :key="key"
                      :label="item"
                      :value="item"
                      >{{ item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div class="cankaowx-more" @click="isshowmore = !isshowmore">
                  <span>{{ isshowmore == true ? "展开更多" : "收起" }}</span>
                </div>
              </el-form-item>
              <div class="ydtk">
                <el-checkbox
                  v-model="checkedydtk"
                  label="我已阅读并同意：生成的论文范文仅用于参考，不作为毕业、发表使用"
                  size="large"
                />
              </div>
              <div class="tjlw-btns">
                <el-button
                  size="large"
                  type="primary"
                  :disabled="checkedydtk == false"
                  @click="lijishengcheng"
                  >立即生成</el-button
                >
                <span class="tjlw-btns-link" @click="clickckjg">查看结果</span>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <footernew />
  </div>
</template>

<script setup>
import {
  Delete,
  Edit,
  Search,
  Share,
  TrophyBase,
  Refresh,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// loading
let loading = ref(false);
let loadingtext = ref("生成中，请等待...");
let randsum = 0;
let form = reactive({});

// 展开更多
let isshowmore = ref(true);
// 模板
let moban = reactive({
  id: 0,
  moban: "",
  options: [],
});
// 标题
let title = reactive({
  bt: "",
  oldbt: "",
  isyouhuatitle: true, //是否需要询问优化标题
  istitle: false, //是否显示标题
  changwenben: "", //返回的长文本
  keys: "", //返回的关键词
  titleoptions: [],
});
// 参考文献
let cankaowenxian = reactive({
  list: [],
  checkeds: [], //已勾选的参考文献
  cankaowenxian: "", //最终应用的参考文献
});
let checkedydtk = ref(false);

// 获取模板列表
const getmoban = () => {
  get("/api/Lw/GetMobanList").then((res) => {
    moban.options.push(...res.data);
  });
};
getmoban();

// 选择分类下的模版
const changemoban = (val) => {
  moban.id = moban.options.filter((e) => {
    return val == e.mbname;
  })[0].mbid;
};

// 根据关键词获取标题
const gettitle = async () => {
  title.oldbt = title.bt; //保存原标题，原标题会被选择的标题覆盖
  loadingtext.value = "正在优化标题，请等待...";
  await huoqutitle(1);
};
// 获取标题
const huoqutitle = async (index) => {
  loading.value = true;
  title.titleoptions.length = 0; //清空
  return post("/api/Lw/GetSerchTitle", {
    kw: title.bt,
    mbid: moban.id,
    type1: -1,
    type2: -1,
  })
    .then((res) => {
      loading.value = false;
      title.changwenben = res.data.subtxt;
      title.keys = res.data.keys;
      //标题优化才显示标题
      if (index == 1) {
        res.data.resdata.split("\n").forEach((e, i) => {
          if (i < 10) {
            title.titleoptions.push(e);
          }
        });
        title.istitle = true;
      }
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 选择标题
const selecttitle = (data) => {
  title.bt = data;
  title.istitle = false;
  title.isyouhuatitle = false; //选择了标题就不用弹窗询问是否优化标题
};

// 生成参考文献
const shengchengcankaowenxian = async () => {
  if (title.bt.trim() == "" || moban.id == 0) {
    ElMessage({
      message: "标题和论文模型不能为空",
      duration: 1000,
    });
    return;
  }
  // 是否弹层询问优化标题
  if (title.isyouhuatitle == true) {
    // 弹窗确认是否需要优化标题
    ElMessageBox.confirm("是否需要优化标题?", "提示", {
      confirmButtonText: "立即优化",
      cancelButtonText: "不用了",
      type: "warning",
    })
      .then(() => {
        gettitle();
      })
      .catch(() => {
        zhijieshengchengcankaowenxian();
      });
  } else {
    getcankaowenxian();
  }
};

// 直接用关键词作为题目生成参考文献
const zhijieshengchengcankaowenxian = async () => {
  title.oldbt = title.bt;
  loadingtext.value = "正在获取关键词，请等待...";
  await huoqutitle(2);
  await getcankaowenxian();
};

// 根据标题获取参考文献
const getcankaowenxian = async () => {
  loading.value = true;
  loadingtext.value = "正在获取参考文献，请等待...";
  cankaowenxian.list.length = 0;
  cankaowenxian.checkeds.length = 0;
  return post("/api/Lw/GetCkwxTj?bh=1", {
    sentence: title.bt,
  })
    .then((res) => {
      loading.value = false;
      res.data.list.forEach((e, i) => {
        cankaowenxian.list.push(e);
        // 默认选择前10条参考文献
        if (i < 10) {
          cankaowenxian.checkeds.push(e);
        }
      });
      // 默认10条
      cankaowenxian.checkeds.forEach((e, i) => {
        cankaowenxian.cankaowenxian += `[${i + 1}]${e}\n`;
      });
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 勾选参考文献
const changecankaowenxian = () => {
  cankaowenxian.cankaowenxian = "";
  // 选择的参考文献
  cankaowenxian.checkeds.forEach((e, i) => {
    cankaowenxian.cankaowenxian += `[${i + 1}]${e}\n\n`;
  });
};

// 开始生成
const lijishengcheng = () => {
  if (cankaowenxian.cankaowenxian == "") {
    ElMessage({
      message: "参考文献不能为空",
      duration: 1000,
    });
    return;
  }
  if (title.bt.length > 50) {
    ElMessage({
      message: "标题过长，请优化标题",
      duration: 1000,
    });
    return;
  }
  // 清空
  title.isyouhuatitle = true;
  isshowmore = true;
  cankaowenxian.list.length = 0;
  // 原标题超过30字符，原标题取最新标题
  if (title.oldbt.length > 50) {
    title.oldbt = title.bt;
  }
  post("/api/Lw/SubArt", {
    mbid: moban.id,
    ybt: title.oldbt,
    title: title.bt,
    subtxt: title.changwenben,
    kw: title.keys,
    ckwx: cankaowenxian.cankaowenxian,
  })
    .then((res) => {
      // 清空
      title.oldbt = "";
      title.bt = "";
      title.changwenben = "";
      title.keys = "";
      cankaowenxian.cankaowenxian = "";
      ElMessageBox.alert(
        "正在努力生成中，您可以继续生成其他论文或者关闭此页面，生成结果可在“个人中心-写作记录”中查看。",
        "提示",
        {
          confirmButtonText: "我知道了",
        }
      );
    })
    .catch((err) => {
      // 清空
      cankaowenxian.cankaowenxian = "";
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 查看结果
const clickckjg = () => {
  window.localStorage.setItem("navindex", "2-1-1");
  router.push({
    path: "/xzjl",
    query: {},
  });
};

// loading
const loadingfun = (outtime) => {
  let randNumtemp = Math.floor(Math.random() * 5) + 1;
  if (loading.value) {
    setTimeout(() => {
      randsum += randNumtemp;
      if (randsum < outtime) {
        let temprandnum = ((randsum / outtime) * 100).toFixed(2);
        loadingtext.value = `正在生成中，当前进度${temprandnum}%，请耐心等待...`;
        loadingfun(outtime);
      } else {
        loadingtext.value = `正在生成中，当前进度99.99%，请耐心等待...`;
      }
    }, randNumtemp * 1000);
  }
};
</script>

<style scoped>
:deep(.el-input-group__prepend .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.el-input-group__append .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.el-checkbox) {
  white-space: normal;
}
:deep(.el-form-item__label) {
  font-weight: bold;
}
:deep(.tjlw-btns .el-button) {
  width: 200px;
  height: 50px;
  font-size: 16px;
}
.main {
  width: 100%;
  height: 100%;
  background: #fafafa;
  min-width: 1800px;
}

.ny-zhuti {
  position: relative;
  width: 100%;
  min-width: 1800px;
  min-height: 600px;
  height: calc(100% - 302px);
}

/* 流程 */
.ny-liucheng {
  position: absolute;
  left: 200px;
  top: 0;
  width: 400px;
  height: 100%;
  overflow-y: scroll;
  background: url('/img/common/bg-side.9a695b46.png') no-repeat;
  background-size: 100% 100%;
}
.ny-liucheng-title{
    margin-top: 82px;
    margin-bottom: 56px;
    font-size: 28px;
    color: #48a4ff;
    text-align: center;
   
    opacity: 1;
}
.ny-liucheng-li-title{
    width: 320px;
    height: 38px;
    background: linear-gradient(270deg, rgba(218, 249, 248, .6), rgba(179, 225, 251, .6));
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid hsla(0, 0%, 100%, .51);
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-left: 12px;
    font-weight: bold;
}
.ny-liucheng-li{
    width: 320px;
    height: auto;
    font-size: 14px;
    background: hsla(0, 0%, 100%, .3);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid hsla(0, 0%, 100%, .6);
    margin: 15px auto 20px;
}
.ny-liucheng-li-title-icon{
  width: 18px;
    height: 18px;
    border-radius: 50%;
    background: linear-gradient(147deg, #48b6ff, #4891ff);
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    margin-right: 12px;
}







.ny-neirong {
  position: absolute;
  left: 600px;
  top: 0;
  width: calc(100% - 600px);
  min-width: 1200px;
  height: 100%;
  overflow-y: scroll;
}

.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.tjlw {
  width: 800px;
  margin: auto;
}
.tjlw-title {
  font-size: 30px;
  font-weight: 400;
  color: #3f4654;
  line-height: 42px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tjlw-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tjlw-img-txt {
  position: absolute;
  left: calc(50% - 400px);
  top: 36px;
  z-index: 1;
  width: 800px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.biaotiyouhua-item {
  position: relative;
}
.biaotiyouhua {
  position: absolute;
  left: 0;
  top: 35px;
  width: 800px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #fff;
  box-shadow: 0 6px 20px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #ececee;
  z-index: 10;
}
.biaotiyouhua-title {
  display: flex;
  align-items: center;
}
.biaotiyouhua-bt {
  padding: 2px 0;
}
.biaotiyouhua-bts {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.biaotiyouhua-bts:hover {
  background: #f1f5ff;
  color: #2489f2;
}
.biaotiyouhua-hyp {
  width: 70px;
  cursor: pointer;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  color: #2489f2;
}
.tjlw-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px 0;
}
.cankaowx {
  width: 800px;
  height: 328px;
  overflow: hidden;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx2 {
  width: 800px;
  height: auto;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx-more {
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.cankaowx-more > span {
  cursor: pointer;
  color: #e6a23c;
}
.cankaowx-more > span:hover {
  text-decoration: underline;
}

.loadings {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.tjlw-btns-link {
  margin-left: 40px;
  font-size: 14px;
  color: #2489f2;
  cursor: pointer;
}
.tjlw-btns-link:hover {
  text-decoration: underline;
}
.ckwx-btn {
  padding: 10px 0 0 0;
}

/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
